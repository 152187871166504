import styled from 'styled-components';

export const Container = styled.div`
  background-color: #0181ca40;
  color: #fff;
  border-radius: 4px;
  border: none;
  padding: 8px 60px;
  font-size: 18px;
  transition: all 0.2s;
  cursor: not-allowed;
`;
